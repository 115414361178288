<template>
  <div class="container" style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">{{this.pageName}}</h5>
    </div>
    <hr>

    <form @submit.prevent="handleBaFormSubmission">
        <div class="form-group">
          <label for="baName" class="required">Bank Account Name</label>
          <input name="baName" type="text" class="form-control" v-model="baName" />
          <ul class="error-feedback" v-if="baNameErrors.length > 0" v-html="baNameErrors"></ul>
        </div>

        <div class="form-group">
          <label for="baType" class="required">Bank Account Type</label>
          <select name="baType" class="form-control" v-model="baType">
            <option value="checking">Checking</option>
            <option value="saving">Saving</option>
          </select>
        </div>



      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                  ></span>
          {{this.submitBtnTxt}}
        </button>
      </div>
    </form>
  </div>

</template>

<script>

export default {
  name: 'BankAccount',
  components: {

  },
  data(){

    return {
      successful: false,
      loading: false,
      message: "",
      userId:null,
      userName:'',
      baName:'',
      baNameErrors:'',
      baType:'',
      pageName:'',
      submitBtnTxt:'',
      hasValidationError:false,
      formData:{},

    };
  },
  computed:{

  },
  methods:{
    getBankAccount: function(){
      this.axios
          .post(this.$hostApi,{task:'getBA', userId:this.userId, baId:this.baId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.baName = response.data.ACCOUNT_NAME;
            this.baType = response.data.ACCOUNT_TYPE;
            this.pageName = 'Edit Bank Account - ' + this.baName;

          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },

    handleAddNewBankAccount() {
      this.axios
          .post(this.$hostApi,{task:'addNewBankAccount', userId:this.userId, data:this.formData},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.message = response;
            this.successful = true;
            this.$root.gotoRoute({ path: '/settings/bas', query: { userid: this.userId }})

            this.loading = false;
            //console.log(response);
          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },

    handleUpdateBankAccount() {
      this.axios
          .post(this.$hostApi,{task:'updateBA', userId:this.userId, baId:this.baId, data:this.formData},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.message = response;
            this.successful = true;
            this.$root.gotoRoute({ path: '/settings/bas', query: { userid: this.userId }})

            this.loading = false;
            //console.log(response);
          })
          .catch(error => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            this.loading = false;
          });

    },


    handleBaFormSubmission(){

      this.$root.playClickSound();

      this.message = "";
      this.successful = false;
      this.loading = true;

      this.hasValidationError = false;
      this.validateBaName();
      if(this.hasValidationError){
        this.loading = false;
        return;
      }

      this.formData = {
        'baName':this.baName,
        'baType':this.baType
      };




      if(this.pageMode === 'update'){
        this.handleUpdateBankAccount();
      }else{
        this.handleAddNewBankAccount();
      }

    },

    validateBaName(){
      this.baNameErrors = this.$root.validateLength(this.baName,0,30);
      if(this.baNameErrors.length > 0){
        this.hasValidationError = true;
      }
    },
  },
  mounted() {
    document.title = "Edit Bank Account";

    this.userId = this.$route.query.userid;
    this.baId = this.$route.query.baId;
    this.userName = this.$route.query.userName;


    if(this.baId){
      this.pageMode = 'update';
      this.submitBtnTxt = 'Update';
      this.getBankAccount();
    }else{
      this.baId = 'new';
      this.pageName = 'Add a Bank Account for ' + this.userName;
      this.submitBtnTxt = 'Add';
    }
  },

}
</script>

<style>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.small, small {
  font-size: 80% !important;
  font-weight: 400;
}

.required:after {
  content: " *";
  color: red;
}

</style>